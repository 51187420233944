@import "../../Styles/settings";

.list_move:hover {
    background-color: #EEEEEE;
}

.list_documents {

    .folder_skill {

        .accordion-button {
            background-color: $maincolor1;
            color: #FFFFFF;
            border-radius: 10px;
            height:50px;
            margin-top:5px;
        }
    }

    .folder_worldskills {

        .accordion-button {
            background-color: $maincolor2;
            color: #FFFFFF;
            border-radius: 10px;
            height:50px;
            margin-top:5px;
        }
    }
}

.right_menu {

    .right_button {
        color:#000000;
        width:200px;
        height: 50px;
        display: block;
        margin-bottom: 10px;
        border-radius: 5px;
        border-color: #EEEFFF;
        background-color: #FFFFFF;
    }

}

.editor-class {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
}

.card {
    margin-bottom:15px;
}