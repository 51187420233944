@import "./settings";
@import "./header";
@import "./displayer";
@import url(http://fonts.googleapis.com/css?family=Roboto+Slab|Open+Sans:400italic,700italic,400,700);


body{
    font-family: $body-font;
    font-weight: normal;
    background-color: #F9FBFD;
}

.root {
    padding-left:0px;
}