@import "../../Styles/settings";


.img_title {
    width: 40px;
}

.title{
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
}

.hometitle {
    font-size: 20px;
}

.hometitle_name {
    color: $maincolor1;
    font-weight: bold;
}

a {
    text-decoration: none;
}

.navboard {
    height:130px;
    width:180px;
    margin:10px;
    text-align: center;
    font-size: 14px;
    color:#8F9BB3;
    position : relative;
    border: 1px solid #EFF4FA;

    .tooltip_navboard{
        position:absolute;
        top: 110px;
        right: 10px;
        float: right;
        font-size: 8px;
        border-radius : 50%;
        width:12px;
        height:12px;
        border : 1px #8F9BB3 solid;
    }

    .img_navboard{
        width:50px;
        margin-bottom:10px;
    }

}

.widget {
    margin-bottom: 30px;

    .widgetheader {
        font-size: 20px;
    }

    .img_title {
        width: 40px;
    }


    .taskswidget {
        background-color: $maincolor1;
        color : #FFFFFF;

        .taskline {
            background-color: $maincolor1;
            color : #FFFFFF;
            padding: 15px;

            .widget_info_task {
                text-align: right;
                color: #58E096;
                font-size: 10px;
    
                .widget_task_icon {
                    font-size: 10px;
                    width: 15px;
                    height: 15px;
                    padding-top: 0px;
                    padding-right: 4px;
                    display:block;
                    position:relative;
                    float:right;
                    bottom:5px;
                    border-radius : 50%;
                    border : 1px #58E096 solid;
                }
            }
        }

    }

    .add_button {
        float:right;
    }

}

.task_done {
    background-color: rgb(95, 209, 95);
}

.task_a {
    color: white;
}

.task_a:hover {
    color: white;
}