@import "../../Styles/settings";

.competitor input[type='radio'] {
    -webkit-appearance:none;
    box-shadow:0 0 20px 0px $competitorcolor inset;
    margin-left: 5px;
    margin-right: 5px;
  }

.competitor input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: $competitorcolor;
}

.competitor input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}

.coach input[type='radio'] {
  -webkit-appearance:none;
  box-shadow:0 0 20px 0px $coachcolor inset;
  margin-left: 5px;
  margin-right: 5px;
}

.coach input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: $coachcolor;
}

.coach input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}

.team input[type='radio'] {
  -webkit-appearance:none;
  box-shadow:0 0 20px 0px $teamcolor inset;
  margin-left: 5px;
  margin-right: 5px;
}

.team input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: $teamcolor;
}

.team input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}

.expert input[type='radio'] {
  -webkit-appearance:none;
  box-shadow:0 0 20px 0px $expertcolor inset;
  margin-left: 5px;
  margin-right: 5px;
}

.expert input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: $expertcolor;
}

.expert input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}

.tl input[type='radio'] {
  -webkit-appearance:none;
  box-shadow:0 0 20px 0px $tlcolor inset;
  margin-left: 5px;
  margin-right: 5px;
}

.tl input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: $tlcolor;
}

.tl input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}

.st input[type='radio'] {
  -webkit-appearance:none;
  box-shadow:0 0 20px 0px $stcolor inset;
  margin-left: 5px;
  margin-right: 5px;
}

.st input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: $stcolor;
}

.st input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}

.staff input[type='radio'] {
  -webkit-appearance:none;
  box-shadow:0 0 20px 0px $staffcolor inset;
  margin-left: 5px;
  margin-right: 5px;
}

.staff input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: $staffcolor;
}

.staff input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}


.tab_contacts {

  .round_competitor {
    font-size: 8px;
    border-radius : 50%;
    width:15px;
    height:15px;
    border : 1px $competitorcolor solid;
    display: block;
    background-color: $competitorcolor;
  }

  .round_coach {
    font-size: 8px;
    border-radius : 50%;
    width:15px;
    height:15px;
    border : 1px $coachcolor solid;
    display: block;
    background-color: $coachcolor;
  }

  .round_team {
    font-size: 8px;
    border-radius : 50%;
    width:15px;
    height:15px;
    border : 1px $teamcolor solid;
    display: block;
    background-color: $teamcolor;
  }

  .round_expert {
    font-size: 8px;
    border-radius : 50%;
    width:15px;
    height:15px;
    border : 1px $expertcolor solid;
    display: block;
    background-color: $expertcolor;
  }

  .round_tl {
    font-size: 8px;
    border-radius : 50%;
    width:15px;
    height:15px;
    border : 1px $tlcolor solid;
    display: block;
    background-color: $tlcolor;
  }

  .round_st {
    font-size: 8px;
    border-radius : 50%;
    width:15px;
    height:15px;
    border : 1px $stcolor solid;
    display: block;
    background-color: $stcolor;
  }

  .round_staff {
    font-size: 8px;
    border-radius : 50%;
    width:15px;
    height:15px;
    border : 1px $staffcolor solid;
    display: block;
    background-color: $staffcolor;
  }

  text-align: center;
  background-color: #FFFFFF;

  .tab_contacts_header {
    height:70px;
    background: #EFF4FA;
    color:#8F9BB3;
    border-bottom: 2px solid #EFF4FA;
    vertical-align: middle;

    th {
      font-weight: normal;
    }
  }

  .tab_contacts_body {
    height:70px;
    vertical-align: middle;
    border-bottom: 1px solid #EFF4FA;

    .icon_action{
      margin-right:15px;
    }
  }
}