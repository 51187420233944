@import "../../Styles/settings";

// "#f08700",
// "#efca08",
// "#00a6a6",
// "#bbdef0",
// "#42e2b8",
// "#eb8a90",
// "#f3dfbf",
// "#4e4ef8",
// "#878787",
// "#f9d961",
// "#ff0000",
// "#014a01",
// "#06357a",
// "#ffc183",
// "#00a95c",
// "#7732a6",
// "#2b0e4f",
// "#aaaaaa",
// "#262626",
// "#4568dc",
.progress {
.bg-#44c8ec{
    background-color: #44c8ec ;
}
}
.card-critere {
    width: 47%;
    margin: 10px;
    padding: 0px;

 .header-card-critere{
    border: 1px solid #EEE;
    background-color: #FFFFFF;
    padding-top: 15px;
    font-size: 20px;
    height: 60px;
 }   

.add-souscritere{
    position: absolute;
    bottom: 5px;
    right: 5px;
}
}

.legende-resultats{
    margin-bottom: 30px;
    font-size: small;
}

.date-resultats{
    font-size: medium;
    text-align: center;
    background-color: #FFFFFF;
    border: 1px solid #eee;
    padding-top: 15px;
    margin-bottom:10px;
}

.right_menu {

    .right_button {
        color:#000000;
        width:200px;
        height: 50px;
        display: block;
        margin-bottom: 10px;
        border-radius: 5px;
        border-color: #EEEFFF;
        background-color: #FFFFFF;
    }

}

.round_critere {
    font-size: 8px;
    border-radius : 50%;
    width:20px;
    height:20px;
    display: inline-block;
    border : 0px;
    margin-right:10px;
}

.round_toeval {
    font-size: 8px;
    border-radius : 50%;
    width:10px;
    height:10px;
    border : 1px $toevalcolor solid;
    display: inline-block;
    background-color: $toevalcolor;
    margin-right:10px;
  }

.round_preparing {
    font-size: 8px;
    border-radius : 50%;
    width:10px;
    height:10px;
    border : 1px $preparingcolor solid;
    display: inline-block;
    background-color: $preparingcolor;
    margin-right:10px;
}

.round_near {
    font-size: 8px;
    border-radius : 50%;
    width:10px;
    height:10px;
    border : 1px $nearcolor solid;
    display: inline-block;
    background-color: $nearcolor;
    margin-right:10px;
}

.round_ready {
    font-size: 8px;
    border-radius : 50%;
    width:10px;
    height:10px;
    border : 1px $readycolor solid;
    display: inline-block;
    background-color: $readycolor;
    margin-right:10px;
}