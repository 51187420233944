.displayer {
    padding-left:50px;
    padding-right:50px;
    margin-left: 50px;
    padding-top:30px;
    z-index: 0;

    .header {

        margin-bottom: 20px;

        .img_title {
            width: 40px;
        }

        .title{
            font-size: 20px;
            font-weight: bold;
            padding-left: 10px;
        }

    }
}