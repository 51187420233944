$maincolor1 : #164EE2;
$maincolor2 : #C30B0B;

$teamcolor : #73b6dd;
$coachcolor : #FA6400;
$competitorcolor : #23155e;
$expertcolor : #0091FF;
$tlcolor : #2d8d6d;
$stcolor : #c0da2d;
$staffcolor : #B620E0;

$toevalcolor : #000000;
$preparingcolor : #E02020;
$nearcolor : #F59E2D;
$readycolor : #58E096;

$body-font : 'Open Sans', sans-serif;