@import "../../Styles/settings";

.add_calendar{
    background-color: $maincolor1;
    color: #FFFFFF;
    border-radius: 20%;
    margin-bottom: 10px;
    font-size: 10px;
}

.planning {
    width: auto;
    border: 0px;
    border-radius: 10px;
}


.event_blue input[type='radio'] {
    -webkit-appearance:none;
    box-shadow:0 0 20px 0px #0095FF inset;
  }

.event_blue input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: #0095FF;
}

.event_blue input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}

.event_green input[type='radio'] {
  -webkit-appearance:none;
  box-shadow:0 0 20px 0px #00E096 inset;
}

.event_green input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: #00E096;
}

.event_green input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}

.event_orange input[type='radio'] {
  -webkit-appearance:none;
  box-shadow:0 0 20px 0px #FFB45E inset;
}

.event_orange input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: #FFB45E;
}

.event_orange input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}

.event_red input[type='radio'] {
  -webkit-appearance:none;
  box-shadow:0 0 20px 0px #FF2D55 inset;
}

.event_red input[type='radio']:checked {
  -webkit-appearance:none;
  box-shadow:0 0 1px 0px white inset;
  background-color: #FF2D55;
}

.event_red input[type='radio']:hover {
  box-shadow:0 0 10px 0px white inset;
}