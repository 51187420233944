@import "../../Styles/settings";

@media screen and (max-width: 600px) {
    .navigation_left {
      visibility: hidden;
      display: none;
    }
  }

.navigation_left {

    z-index: 1;
    padding-left:0px;
    float:left;
    height: 90vh;
    position: fixed;
    top: 90px;

    #triangle-bottomleft {
        border-bottom: 30px solid $maincolor1;
        border-right: 50px solid transparent;
        background-color: #FFFFFF;
        position:fixed;
    }

    .vertical_menu{
        background: linear-gradient($maincolor1, $maincolor2);
        width:50px;
        max-width: 50px;
        height:100%;
        padding-top:30px;
    }

    a {
        color : #333333;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        margin-top: 0px;
        padding: 10px;
        display: block;
    }
}