.top {
    z-index: 1;
    padding-top: 10px;
    background: /* gradient can be an image */
    linear-gradient(
    to left, 
    #F9FBFD 0%,
    #FFFFFF 90%,
    )
    left 
    bottom
    no-repeat
    fixed;

    .logo {
        img{
            width: 150px;
        }
    }

    .menu_dropdown {
        display: inline-block;
    }

    .menu_top {
        font-size: 1.3em;
    }

    .rightpanel {

        background: /* gradient can be an image */
        linear-gradient(
        to left, 
        $maincolor1 0%,
        $maincolor2 50%,
        #F9FBFD 90%,
        )
        left 
        bottom
        no-repeat; 
        background-size:100% 3px ;/* if linear-gradient, we need to resize it */
        padding: 10px;

        a{
            color: #000;
            padding: 10px;
            margin: 5px;
        }

    }

    .sponsor {
        margin-left: 50px;
        font-size: small;
    }
}