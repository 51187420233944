@import "../../Styles/settings";

.reply {
    background-color: powderblue;
    font-style: italic;
    font-size: small;
    margin: 5px;
    padding: 5px;
}

.public-DraftStyleDefault-block {
    margin: 0em 0;
}

.comment-editor {
    border : 1px solid #DDDDDD;
    background-color: #EEE;
    padding: 5px;
}

.buttons_comment {
    float:right;
}