@import "../../Styles/settings";



.sujet {
    height:100%;
    width:300px;
    margin:10px;
    text-align: center;
    font-size: 14px;
    color:#8F9BB3;
    position : relative;
    border: 1px solid #EFF4FA;

    .tooltip_sujet{
        position:absolute;
        top: 10px;
        right: 10px;
        float: right;
        font-size: 18px;
        border-radius : 50%;
        width:26px;
        height:26px;
    }

    .img_sujet{
        width:50px;
        margin-bottom:10px;
    }

}